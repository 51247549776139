<template>
  <v-dialog persistent max-width="500" v-model="dialog" class="elevation-0 ">
    <v-btn style="position:absolute;z-index: 1;" icon dark @click="close">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <router-link
      class="elevation-0"
      :to="`/yo/${$storeNiu.getTextOrInt('user')}?renueva=membresia`"
    >
      <v-img
        class="elevation-0"
        src="https://i.postimg.cc/4sFDhz2v/popup.jpg"
        :to="`/yo/${$storeNiu.getTextOrInt('user')}?renueva=membresia`"
      >
      </v-img>
    </router-link>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      notifications: false,
      sound: true,
      widgets: false
    }
  },
  methods: {
    close () {
      this.$emit('closeDialogVecimiento', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.pronto {
  position: absolute;
  top: 0;
  background-color: red;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}

.activo {
  position: absolute;
  top: 0;
  background-color: green;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  right: 0;
}
</style>
