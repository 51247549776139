<template>
  <div class="pa-5">
    <div class="title-resumen">
      <span class="title">
        Resumen de tu ruta de aprendizaje para el grado "{{ grado }}"
      </span>
    </div>
    <v-timeline dense light>
      <v-timeline-item small dark v-for="(_module, index) in ruta" :key="'modulo'+index"
        >{{ _module.moduloTitle }}
      </v-timeline-item>
    </v-timeline>
  </div>
</template>
<script>
export default {
    name:'CrearRutaAprendizajeExplora',
  props: {
    grado: {
      type: String,
      required: true
    },
    ruta: {
      type: Array,
      required: true
    }
  }
}
</script>
<style>
.v-timeline-item__dot--small .v-timeline-item__inner-dot {
  height: 10px;
  margin: 2px;
  width: 10px;
}

.v-timeline-item__dot--small {
  height: 15px;
  left: -5px;
  width: 15px;
}
.v-timeline--dense .v-timeline-item__body {
  max-width: calc(100% - 96px);
  left: 15px;
}
.title-resumen {
  font-size: 1.25rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: 'Roboto', sans-serif !important;
  text-align: center;
}
</style>
