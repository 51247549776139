<template>
  <div style="max-width:80%" v-if="$vuetify.breakpoint.name!='xs'">
    <h1 class="niu-text-black ">
      {{ mensaje }}
    </h1>
  </div>
</template>
<script>
export default {
  props: {
    mensaje: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
.niu-text-black {
  color: rgba(0, 0, 0, 1) !important;
  font-weight: 300;
  font-size: 1.6rem;
}
@media (max-width: 960px) and (min-width: 600px) {
  .niu-text-black {
    color: rgba(0, 0, 0, 1) !important;
    font-weight: 300;
    font-size: 1.4rem;
  }
}

@media (max-width: 600px) and (min-width: 400px) {
  .niu-text-black {
    color: rgba(0, 0, 0, 1) !important;
    font-weight: 300;
    font-size: 1.2rem;
  }
}
@media (max-width: 400px) and (min-width: 300px) {
  .niu-text-black {
    color: rgba(0, 0, 0, 1) !important;
    font-weight: 300;
    font-size: 1rem;
  }
}
</style>
