<template>
  <v-sheet class="niu-transparent-explora" elevation="0" >
    <v-slide-group v-model="model" active-class="success" show-arrows>
      <v-slide-item
        v-for="video in exploraVideos"
        :key="'modulo_' + video.moduloId + 'video_' + video.videoId"
        height="50px"
      >
        <v-img
          @click="activarModel(video)"
          class="white--text align-end rounded-0"
          :aspect-ratio="16 / 9"
          :src="
            require('../../assets/descubre/' +
              (video.videoImg ? video.videoImg : 'proximamente.png'))
          "
          lazy-src="../../assets/client-loader.gif"
        >
          <p style="word-break: break-word;font-size: 1.2rem;">
            <label style="word-break: break-word;font-size: 1.2rem;">
              <v-avatar>
                <v-icon dark size="40px">
                  mdi-play-circle
                </v-icon>
              </v-avatar>
              {{ video.videoTitle }}
            </label>
          </p>
        </v-img>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
</template>
<script>
import { mapMutations } from 'vuex'
import { EventBus } from '../../plugins/event-bus'
export default {
  props: {
    trackCode: String,
    trackTitle: String,
    trackId: Number
  },
  components: {},
  data: () => ({
    model: {},
    datos: {},
    videos: null,
    exploraVideos: null
  }),
  mounted () {
    this.$http
      .get('/Tracks/TrackDescubre')
      .then(response => {
        this.exploraVideos = response.data
      })
      //eslint-disable-next-line
      .catch(error => {})
  },
  methods: {
    ...mapMutations('carruseles', ['SET_MODULO', 'SET_VIDEONUMBER']),

    activarModel (video) {
      this.modalVideo = true
      EventBus.$emit('event_videomain', { pause: true })
      EventBus.$emit('eventmodelvideo', { video, activar: this.modalVideo })
    },
    closeOpen () {
      this.modalVideo = false
      EventBus.$emit('eventmodelvideo', {
        video: null,
        activar: this.modalVideo
      })
    },
    classFondo (modulo, hover) {
      return {
        'niu-background-gradient-emprendimientonegocio':
          modulo.areaconocimientoCode == 'emprendimientonegocio' && hover,
        'niu-background-gradient-liderazgo':
          modulo.areaconocimientoCode == 'liderazgo' && hover,
        'niu-background-gradient-comunicacion':
          modulo.areaconocimientoCode == 'comunicacion' && hover,
        'niu-background-gradient-desarrollofisico':
          modulo.areaconocimientoCode == 'desarrollofisico' && hover,
        'niu-background-gradient-desarrollopersonal':
          modulo.areaconocimientoCode == 'desarrollopersonal' && hover,
        'niu-background-gradient-conocimientotecnico':
          modulo.areaconocimientoCode == 'conocimientotecnico' && hover
      }
    },
    aspectoRatio () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 4 / 3
        case 'sm':
          return 4 / 3
        case 'md':
          return 16 / 9
        case 'lg':
          return 16 / 9
        case 'xl':
          return 16 / 9
      }
    },
    activeModulo (modulo, numberVideo = 1) {
      document.documentElement.classList.add('niu-overflow-hidden')
      this.$store.commit('dialog', {
        dialog: true,
        modulo,
        numberVideo: numberVideo - 1
      })
      this.SET_VIDEONUMBER(numberVideo - 1)
      this.$router.push({
        name: 'modulo',
        params: { id: modulo.videId }
      })
    },
    classModuloCarrusel (video, hover) {
      return {
        'niu-border-niufleex-1-light': hover
      }
    },
    barraInferiorModuloCarrusel (video, hover) {
      return {
        'niu-barra': true,
        'niu-background-gradient-niufleex-1': hover
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.niu-transparent-explora {
  background-color: transparent !important;
}
.v-responsive.v-image {
  border-radius: 16px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0;
}
.texto_modulo {
  transform: rotate(-45deg);
  display: block;
  position: absolute;
  top: 40px;
  left: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 1px 1px #000;
}
.box_vineta {
  width: 100%;
  height: 100%;
  border: 0px solid transparent;
  background: linear-gradient(
    -45deg,
    transparent 0,
    transparent 70%,
    rgba(0, 0, 0, 0.75) 0,
    rgba(0, 0, 0, 0.75) 30%
  );
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
}
// $width: ;
// * {
//   position: relative;
// }

.niu-rounded-4 {
  border-radius: 4px;
}
.niu-rounded-tl-4 {
  border-top-left-radius: 4px;
}
.niu-rounded-tr-4 {
  border-top-right-radius: 4px;
}
.niu-rounded-br-4 {
  border-bottom-right-radius: 4px;
}
.niu-rounded-bl-4 {
  border-bottom-left-radius: 4px;
}
.niu-rounded-b-4 {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.niu-rounded-t-4 {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.niu-title-carousel {
  color: #fff;
  text-shadow: 1px 1px 1px #000;
}
.niu-position-relative {
  position: relative;
}
.niu-overflow-hidden {
  overflow: hidden;
}
.niu-text-carrusel {
  min-height: 100px;
}
.niu-card-main {
  width: 300px;
  height: 270px;
  margin-right: 30px;
  margin-bottom: 15px;
  // padding: 2px;
  background-color: black;
  border: 1px solid #000;
  border-radius: 16px !important;
}
.niu-width-track {
  width: 98%;
  left: 1%;
  height: 360px;
  background-color: black;
}
.niu-carousel-texto-modulo {
  background-color: rgba(0, 0, 0, 0.4);
  text-shadow: 1px 1px 1px #000;
  text-align: left;
  bottom: 0;
  height: 60px;
  padding: 4px;
  font-size: 1.4rem;
  /* text-transform: uppercase; */
  font-weight: bold;
  word-break: break-word;
  text-transform: none;
  /* line-height: 2rem; */
  margin-bottom: 40px;
  display: block;
}
.cicle-ball-area {
  width: 10px;
  height: 10px;
  background: white;
  display: inline-block;
  border-radius: 50%;
  margin: 0 4px 0 0;
}
/* .niu-width-track {
  max-width: 100%;
} */
// @media (min-width: 300px) and (max-width: 320px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 280px;
//   }
// }
// @media (min-width: 320px) and (max-width: 340px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 300px;
//   }
// }
// @media (min-width: 340px) and (max-width: 380px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 320px;
//   }
// }
// @media (min-width: 380px) and (max-width: 400px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 360px;
//   }
// }
// @media (min-width: 400px) and (max-width: 420px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 320px;
//   }
// }
// @media (min-width: 420px) and (max-width: 440px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 340px;
//   }
// }
// @media (min-width: 440px) and (max-width: 460px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 365px;
//   }
// }
// @media (min-width: 460px) and (max-width: 480px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 385px;
//   }
// }
// @media (min-width: 480px) and (max-width: 500px) {
//   .niu-card-main {
//     height: 170px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 405px;
//   }
// }
// @media (min-width: 500px) and (max-width: 520px) {
//   .niu-card-main {
//     height: 175px;
//     width: 225px;
//   }
//   .niu-width-track {
//     width: 465px;
//     height: 240px;
//   }
//   .niu-text-carrusel {
//     height: 200px;
//   }
// }
// @media (min-width: 520px) and (max-width: 540px) {
//   .niu-card-main {
//     height: 130px;
//     width: 158px;
//   }
//   .niu-width-track {
//     width: 495px;
//     height: 250px;
//   }
//   .niu-text-carrusel {
//     height: 200px;
//   }
// }
// @media (min-width: 540px) and (max-width: 560px) {
//   .niu-card-main {
//     height: 199px;
//     width: 224px;
//   }
//   .niu-width-track {
//     width: 465px;
//   }
// }
// @media (min-width: 560px) and (max-width: 580px) {
//   .niu-card-main {
//     height: 199px;
//     width: 234px;
//   }
//   .niu-width-track {
//     width: 485px;
//   }
// }
// @media (min-width: 580px) and (max-width: 600px) {
//   .niu-card-main {
//     height: 199px;
//     width: 244px;
//   }
//   .niu-width-track {
//     width: 505px;
//   }
// }
// @media (min-width: 600px) and (max-width: 620px) {
//   .niu-card-main {
//     height: 199px;
//     width: 255px;
//   }
//   .niu-width-track {
//     width: 525px;
//   }
// }
// @media (min-width: 620px) and (max-width: 640px) {
//   .niu-card-main {
//     height: 191px;
//     width: 261px;
//   }
//   .niu-width-track {
//     width: 545px;
//   }
// }
// @media (min-width: 640px) and (max-width: 660px) {
//   .niu-card-main {
//     height: 214px;
//     width: 273px;
//   }
//   .niu-width-track {
//     width: 565px;
//   }
// }
// @media (min-width: 660px) and (max-width: 680px) {
//   .niu-card-main {
//     height: 282px;
//     width: 188px;
//   }
//   .niu-width-track {
//     width: 585px;
//   }
//   .v-slide-group {
//     height: 300px;
//   }
// }
// @media (min-width: 680px) and (max-width: 700px) {
//   .niu-card-main {
//     height: 150px;
//     width: 195px;
//   }
//   .niu-width-track {
//     width: 605px;
//   }
// }
// @media (min-width: 700px) and (max-width: 720px) {
//   .niu-card-main {
//     height: 150px;
//     width: 195px;
//   }
//   .niu-width-track {
//     width: 625;
//   }
// }
// @media (min-width: 720px) and (max-width: 740px) {
//   .niu-card-main {
//     height: 157px;
//     width: 200px;
//   }
//   .niu-width-track {
//     width: 645;
//   }
// }
// @media (min-width: 740px) and (max-width: 760px) {
//   .niu-card-main {
//     height: 165px;
//     width: 212px;
//   }
//   .niu-width-track {
//     width: 665px;
//   }
// }
// @media (min-width: 760px) and (max-width: 780px) {
//   .niu-card-main {
//     height: 171px;
//     width: 219px;
//   }
//   .niu-width-track {
//     width: 685px;
//   }
// }
// @media (min-width: 780px) and (max-width: 800px) {
//   .niu-card-main {
//     height: 179px;
//     width: 225px;
//   }
//   .niu-width-track {
//     width: 705px;
//   }
// }
// @media (min-width: 800px) and (max-width: 820px) {
//   .niu-card-main {
//     height: 182px;
//     width: 232px;
//   }
//   .niu-width-track {
//     width: 725px;
//   }
// }
// @media (min-width: 820px) and (max-width: 840px) {
//   .niu-card-main {
//     height: 187px;
//     width: 239px;
//   }
//   .niu-width-track {
//     width: 745px;
//   }
// }
// @media (min-width: 840px) and (max-width: 860px) {
//   .niu-card-main {
//     height: 143px;
//     width: 183px;
//   }
//   .niu-width-track {
//     width: 765px;
//   }
// }
// @media (min-width: 860px) and (max-width: 880px) {
//   .niu-card-main {
//     height: 202px;
//     width: 252px;
//   }
//   .niu-width-track {
//     width: 785px;
//   }
// }
// @media (min-width: 880px) and (max-width: 900px) {
//   .niu-card-main {
//     height: 150px;
//     width: 195px;
//   }
//   .niu-width-track {
//     width: 805px;
//   }
// }
// @media (min-width: 900px) and (max-width: 920px) {
//   .niu-card-main {
//     height: 206px;
//     width: 266px;
//   }
//   .niu-width-track {
//     width: 825px;
//   }
// }
// @media (min-width: 920px) and (max-width: 940px) {
//   .niu-card-main {
//     height: 156px;
//     width: 202px;
//   }
//   .niu-width-track {
//     width: 845px;
//   }
// }
// @media (min-width: 940px) and (max-width: 960px) {
//   .niu-card-main {
//     height: 167px;
//     width: 209px;
//   }
//   .niu-width-track {
//     width: 865px;
//   }
// }
// @media (min-width: 960px) and (max-width: 980px) {
//   .niu-card-main {
//     height: 169px;
//     width: 213px;
//   }
//   .niu-width-track {
//     width: 885px;
//   }
// }
// @media (min-width: 980px) and (max-width: 1000px) {
//   .niu-card-main {
//     height: 173px;
//     width: 217px;
//   }
//   .niu-width-track {
//     width: 905px;
//   }
// }
// @media (min-width: 1000px) and (max-width: 1020px) {
//   .niu-card-main {
//     height: 170px;
//     width: 218px;
//   }
//   .niu-width-track {
//     width: 910px;
//   }
// }
// @media (min-width: 1020px) and (max-width: 1040px) {
//   .niu-card-main {
//     height: 178px;
//     width: 227px;
//   }
//   .niu-width-track {
//     width: 945px;
//   }
// }
// @media (min-width: 1040px) and (max-width: 1060px) {
//   .niu-card-main {
//     height: 181px;
//     width: 229px;
//   }
//   .niu-width-track {
//     width: 954px;
//   }
// }
// @media (min-width: 1060px) and (max-width: 1080px) {
//   .niu-card-main {
//     height: 182px;
//     width: 234px;
//   }
//   .niu-width-track {
//     width: 974px;
//   }
// }
// @media (min-width: 1080px) and (max-width: 1100px) {
//   .niu-card-main {
//     height: 183px;
//     width: 237px;
//   }
//   .niu-width-track {
//     width: 985px;
//   }
// }
// @media (min-width: 1100px) and (max-width: 1120px) {
//   .niu-card-main {
//     height: 187px;
//     width: 241px;
//   }
//   .niu-width-track {
//     width: 1000px;
//   }
// }
// @media (min-width: 1120px) and (max-width: 1140px) {
//   .niu-card-main {
//     height: 190px;
//     width: 246px;
//   }
//   .niu-width-track {
//     width: 1020px;
//   }
// }
// @media (min-width: 1140px) and (max-width: 1160px) {
//   .niu-card-main {
//     height: 194px;
//     width: 251px;
//   }
//   .niu-width-track {
//     width: 1040px;
//   }
// }
// @media (min-width: 1160px) and (max-width: 1180px) {
//   .niu-card-main {
//     height: 159px;
//     width: 203px;
//   }
//   .niu-width-track {
//     width: 1060px;
//   }
// }
// @media (min-width: 1180px) and (max-width: 1200px) {
//   .niu-card-main {
//     height: 205px;
//     width: 262px;
//   }
//   .niu-width-track {
//     width: 1080px;
//   }
// }
// @media (min-width: 1200px) and (max-width: 1220px) {
//   .niu-card-main {
//     height: 209px;
//     width: 267px;
//   }
//   .niu-width-track {
//     width: 1100px;
//   }
// }
// @media (min-width: 1220px) and (max-width: 1240px) {
//   .niu-card-main {
//     height: 210px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1120px;
//   }
// }
// @media (min-width: 1240px) and (max-width: 1260px) {
//   .niu-card-main {
//     height: 176px;
//     width: 219px;
//   }
//   .niu-width-track {
//     width: 1140px;
//   }
// }
// @media (min-width: 1260px) and (max-width: 1280px) {
//   .niu-card-main {
//     height: 176px;
//     width: 219px;
//   }
//   .niu-width-track {
//     width: 1140px;
//   }
// }
// @media (min-width: 1280px) and (max-width: 1300px) {
//   .niu-card-main {
//     height: 173px;
//     width: 223px;
//   }
//   .niu-width-track {
//     width: 1160px;
//   }
// }
// @media (min-width: 1300px) and (max-width: 1320px) {
//   .niu-card-main {
//     height: 177px;
//     width: 227px;
//   }
//   .niu-width-track {
//     width: 1180px;
//   }
// }
// @media (min-width: 1320px) and (max-width: 1340px) {
//   .niu-card-main {
//     height: 180px;
//     width: 231px;
//   }
//   .niu-width-track {
//     width: 1200px;
//   }
// }
// @media (min-width: 1340px) and (max-width: 1360px) {
//   .niu-card-main {
//     height: 184px;
//     width: 236px;
//   }
//   .niu-width-track {
//     width: 1220px;
//   }
// }
// @media (min-width: 1360px) and (max-width: 1380px) {
//   .niu-card-main {
//     height: 188px;
//     width: 241px;
//   }
//   .niu-width-track {
//     width: 1240px;
//   }
// }
// @media (min-width: 1380px) and (max-width: 1400px) {
//   .niu-card-main {
//     height: 188px;
//     width: 241px;
//   }
//   .niu-width-track {
//     width: 1240px;
//   }
// }
// @media (min-width: 1400px) and (max-width: 1420px) {
//   .niu-card-main {
//     height: 189px;
//     width: 243px;
//   }
//   .niu-width-track {
//     width: 1260px;
//   }
// }
// @media (min-width: 1420px) and (max-width: 1440px) {
//   .niu-card-main {
//     height: 192px;
//     width: 247px;
//   }
//   .niu-width-track {
//     width: 1280px;
//   }
// }
// @media (min-width: 1440px) and (max-width: 1460px) {
//   .niu-card-main {
//     height: 195px;
//     width: 251px;
//   }
//   .niu-width-track {
//     width: 1300px;
//   }
// }
// @media (min-width: 1460px) and (max-width: 1480px) {
//   .niu-card-main {
//     height: 197px;
//     width: 255px;
//   }
//   .niu-width-track {
//     width: 1320px;
//   }
// }
// @media (min-width: 1480px) and (max-width: 1500px) {
//   .niu-card-main {
//     height: 201px;
//     width: 259px;
//   }
//   .niu-width-track {
//     width: 1340px;
//   }
// }
// @media (min-width: 1500px) and (max-width: 1520px) {
//   .niu-card-main {
//     height: 205px;
//     width: 263px;
//   }
//   .niu-width-track {
//     width: 1360px;
//   }
// }
// @media (min-width: 1520px) and (max-width: 1540px) {
//   .niu-card-main {
//     height: 230px;
//     width: 290px;
//   }
//   .niu-width-track {
//     width: 1490px;
//     height: 300px;
//   }
// }
// @media (min-width: 1540px) and (max-width: 1560px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1400px;
//   }
// }
// @media (min-width: 1560px) and (max-width: 1580px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1420px;
//   }
// }
// @media (min-width: 1580px) and (max-width: 1600px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1440px;
//   }
// }
// @media (min-width: 1600px) and (max-width: 1620px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1460px;
//   }
// }
// @media (min-width: 1620px) and (max-width: 1640px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1480px;
//   }
// }
// @media (min-width: 1640px) and (max-width: 1660px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1500px;
//   }
// }
// @media (min-width: 1660px) and (max-width: 1680px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1520px;
//   }
// }
// @media (min-width: 1680px) and (max-width: 1700px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1540px;
//   }
// }
// @media (min-width: 1700px) and (max-width: 1720px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1560px;
//   }
// }
// @media (min-width: 1720px) and (max-width: 1740px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1580px;
//   }
// }
// @media (min-width: 1740px) and (max-width: 1760px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1600px;
//   }
// }
// @media (min-width: 1760px) and (max-width: 1780px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1620px;
//   }
// }
// @media (min-width: 1780px) and (max-width: 1800px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1640px;
//   }
// }
// @media (min-width: 1800px) and (max-width: 1820px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1660px;
//   }
// }
// @media (min-width: 1820px) and (max-width: 1840px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1680px;
//   }
// }
// @media (min-width: 1840px) and (max-width: 1860px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1700px;
//   }
// }
// @media (min-width: 1860px) and (max-width: 1880px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1720px;
//   }
// }
// @media (min-width: 1880px) and (max-width: 1900px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1740px;
//   }
// }
// @media (min-width: 1900px) and (max-width: 1920px) {
//   .niu-card-main {
//     height: 209px;
//     width: 271px;
//   }
//   .niu-width-track {
//     width: 1760px;
//   }
// }
// .theme--dark.v-sheet.niu-card-main {
//   background-color: #333333;
//   border-color: #333333;
// }
.niu-hover {
  border-radius: 8px;
  border: 3px solid #444;
  border-radius: 8px;
}
.niu-border-emprendimientonegocio-dark {
  border-color: #ffae04 !important;
}
.niu-border-emprendimientonegocio-light {
  border-color: #ffe800 !important;
}
.niu-border-liderazgo-dark {
  border-color: #f64600 !important;
}
.niu-border-liderazgo-light {
  border-color: #f39d00 !important;
}
.niu-border-desarrollofisico-dark {
  border-color: #770005 !important;
}
.niu-border-desarrollofisico-light {
  border-color: #f40000 !important;
}
.niu-border-desarrollopersonal-dark {
  border-color: #2d6b00 !important;
}
.niu-border-desarrollopersonal-light {
  border-color: #2ae82d !important;
}
.niu-border-conocimientotecnico-dark {
  border-color: #11b29c !important;
}
.niu-border-conocimientotecnico-light {
  border-color: #3effe8 !important;
}
.niu-border-comunicacion-dark {
  border-color: #354CF9 !important;
}
.niu-border-comunicacion-light {
  border-color: #0540f2 !important;
}
.niu-border-niufleex-1-dark {
  border-color: #590d53 !important;
}
.niu-border-niufleex-1-light {
  border-color: #a52882 !important;
}
.niu-barra {
  width: 60%;
  height: 5px;
  background-color: transparent;
  position: absolute;
  bottom: 0;
  z-index: 20;
  border-radius: 2px;
  left: 20%;
}
.v-icon.notranslate.mdi.mdi-chevron-right,
.v-icon.notranslate.mdi.mdi-chevron-left {
  font-size: 36px;
  border: 1px solid;
  border-radius: 50%;
}
.niu-background-gradient-emprendimientonegocio {
  background: rgb(255, 174, 4);
  background: linear-gradient(
    45deg,
    rgba(255, 174, 4, 1) 0%,
    #FFB92E 100%
  );
}
.niu-background-gradient-liderazgo {
  background: rgb(246, 70, 0);
  background: linear-gradient(
    45deg,
    rgba(246, 70, 0, 1) 0%,
    #C10000 100%
  );
}
.niu-background-gradient-desarrollofisico {
  background: #FC3A72;
  background: linear-gradient(
    45deg,
    rgba(119, 0, 5, 1) 0%,
    rgba(244, 0, 0, 1) 100%
  );
}
.niu-background-gradient-desarrollopersonal {
  background: #8AC445;
  background: linear-gradient(
    45deg,
    rgba(45, 107, 0, 1) 0%,
    rgba(42, 232, 45, 1) 100%
  );
}
.niu-background-gradient-conocimientotecnico {
  background: rgb(17, 178, 156);
  background: linear-gradient(
    45deg,
    #40CCB1 0%,
    rgba(62, 255, 232, 1) 100%
  );
}
.niu-background-gradient-comunicacion {
  background: rgb(3, 0, 42);
  background: linear-gradient(45deg, #354CF9 0%, #0540f2 100%);
}

.niu-background-gradient-emprendimientonegocio {
  background: #FFB92E;
  background: linear-gradient(
    45deg,
    #FFB92E 0%,
    rgba(255, 174, 4, 1) 100%
  );
}
.niu-background-gradient-liderazgo {
  background: #C10000;
  background: linear-gradient(
    45deg,
    #C10000 0%,
    rgba(246, 70, 0, 1) 100%
  );
}
.niu-background-gradient-desarrollofisico {
  background: rgba(244, 0, 0, 1);
  background: linear-gradient(
    45deg,
    rgba(244, 0, 0, 1) 0%,
    rgba(119, 0, 5, 1) 100%
  );
}
.niu-background-gradient-desarrollopersonal {
  background: rgba(42, 232, 45, 1);
  background: linear-gradient(
    45deg,
    rgba(42, 232, 45, 1) 0%,
    rgba(45, 107, 0, 1) 100%
  );
}
.niu-background-gradient-conocimientotecnico {
  background: rgba(62, 255, 232, 1);
  background: linear-gradient(
    45deg,
    rgba(62, 255, 232, 1) 0%,
    #40CCB1 100%
  );
}
.niu-background-gradient-comunicacion {
  background: rgb(3, 0, 42);
  background: linear-gradient(45deg, #354CF9 0%, #0540f2 100%);
}

.niu-background-gradient-niufleex-1 {
  background: rgb(3, 0, 42);
  background: linear-gradient(45deg, #590d53 0%, #a52882 100%);
}


</style>
