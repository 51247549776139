<template>
  <v-dialog
    v-model="open"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    v-resize="onResize"
    min-width="600px"
    max-width="600px"
    id="framevimeoniufleexcarrusel"
    ref="framevimeoniufleexcarrusel"
    persistent
  >
    <div class="vimeo-space" style="padding: 56.25% 0 0 0; position: relative;">
      <iframe
        ref="dialogvimeo"
        id="dialogvimeo"
        :src="`https://player.vimeo.com/video/${idVideo}`"
        width="100%"
        :height="`${heightFrame}px`"
        autoplay
        style="height: 100%; left: 0; position: absolute; top: 0; width: 100%;"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
      ></iframe>
      <v-btn
        color="dark darken-1"
        text
        @click="closeOpen"
        style="position:absolute;z-index:3;right:0;top:0; width:20px;font-size:20px;text-shadow:1px 1px #FFF,-1px 1px #FFF,1px -1px #FFF,-1px -1px #FFF"
      >
        X
      </v-btn>
    </div>
  </v-dialog>
</template>
<script>
import { EventBus } from '../../plugins/event-bus'
// eslint-disable-next-line no-unused-vars
import Player from '@vimeo/player'
export default {
  data () {
    return {
      open: false,
      idVideo: 0,
      heightFrame: 300,
      iframedialogvimeo: null,
      playervimeo: null,
      setInter: null
    }
  },
  created () {},
  mounted () {
    EventBus.$on('eventmodelvideo', payload => {
      // eslint-disable-next-line no-console
      console.group('eventmodelvideo')
      this.open = payload.activar
      this.idVideo = payload.video.videoIdVideo

      // eslint-disable-next-line no-console
      // console.log(this.playervimeo)
      this.setInter = setInterval(() => {
        try {
          this.iframedialogvimeo = document.querySelector('#dialogvimeo')
          this.playervimeo = new Player(this.iframedialogvimeo)
          // eslint-disable-next-line no-console
          console.log(this.playervimeo)
          this.playervimeo.ready(e => {
            // eslint-disable-next-line no-console
            console.log(e)
            this.playervimeo.play()
            // eslint-disable-next-line no-console
            console.groupEnd('eventmodelvideo')
          })
          clearInterval(this.setInter)

          // eslint-disable-next-line no-empty
        } catch (error) {}
      }, 1000)
    })
    this.onResize()
  },
  beforeDestroy () {
    this.idVideo = null
  },

  methods: {
    onResize () {
      let aspecto = 9 / 16

      if (
        document.querySelector('#framevimeoniufleexcarrusel') &&
        this.$refs.framevimeoniufleexcarrusel.$el.offsetWidth != 0
      ) {
        let anchoframe = document.querySelector('#framevimeoniufleexcarrusel')
          .offsetWidth
        this.heightFrame = anchoframe * aspecto
        // eslint-disable-next-line no-console
        console.log(
          this.$refs.framevimeoniufleexcarrusel,
          this.$refs.framevimeoniufleexcarrusel.$el.offsetWidth,
          anchoframe,
          aspecto
        )
      } else {
        this.heightFrame = 600 * aspecto
      }
    },
    closeOpen () {
      this.open = false
      EventBus.$emit('event_videomain', { pause: false })
      this.idVideo = null
    }
  }
}
</script>
<style lang="scss" scoped>
.v-tooltip__content {
  position: absolute;
}
.v-dialog__content.v-dialog__content--active {
  position: fixed;
}
.pos-relative {
  position: relative !important;
}
</style>
