<template>
	<v-card
		elevation="0"
		class="mx-auto"
		interval="900000"
		id="banner"
		:height="bannerSeleccionado.is_video ? altoVideo : altoBannerExplora"
	>
		<div
			v-if="bannerSeleccionado.is_video"
			class="vimeo-space"
			style="padding: 30.25% 0 0 0; position: relative"
		>
			<v-row
				style="
					position: absolute;
					top: 0px;
					z-index: 1;
					width: 100%;
					height: 100%;
				"
				align="center"
				class="lightbox white--text pa-2 fill-height"
			>
				<v-col cols="12" sm="8" md="9" lg="7" class="pa-10">
					<div
						class="text-md-h3 text-sm-h4 text-h5 text-lg-h2 font-weight-bold"
					>
						<p v-html="bannerSeleccionado.title"></p>
					</div>
					<br />
					<div
						class="
							text-md-h6 text-sm-subtitle-1 text-subtitle-2 text-lg-h5
							d-none d-sm-flex
						"
					>
						<p v-html="bannerSeleccionado.description"></p>
					</div>
				</v-col>
			</v-row>
			<iframe
				v-intersect="onIntersect"
				:src="`${bannerSeleccionado.url}?api=1&background=1`"
				style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
				frameborder="0"
				width="100%"
				allow="autoplay; fullscreen"
				allowfullscreen
				id="videoholter"
				:height="altoVideo"
				autoplay
				ref="videoBanner"
				:controls="false"
			></iframe>
		</div>
		<a
			:href="bannerSeleccionado.link"
			:target="bannerSeleccionado.targetlink"
			v-else
		>
			<v-img
				:aspect-ratio="aspectoBanner"
				:src="bannerSeleccionado.url"
				style="filter: grayscale(20%)"
			>
				<v-row align="center" class="lightbox white--text pa-2 fill-height">
					<v-col cols="12" sm="8" md="9" lg="7" class="pa-10">
						<div
							class="text-md-h3 text-sm-h4 text-h5 text-lg-h2 font-weight-bold"
						>
							<p v-html="bannerSeleccionado.title"></p>
						</div>
						<br />
						<div
							class="text-md-h6 text-sm-subtitle-1 text-subtitle-2 text-lg-h5"
						>
							<p v-html="bannerSeleccionado.description"></p>
						</div>
					</v-col>
				</v-row>
			</v-img>
		</a>
	</v-card>
</template>
<script>
import Player from "@vimeo/player";
export default {
	async created() {
		// eslint-disable-next-line no-console
		console.log(this.bannerSeleccionado);
		try {
			if (this.is_compraahora) {
				this.bannerExplora = (
					await this.$http.get("/Interface/getBannerCompraAhora")
				).data;
			} else {
				if (this.team == "") {
					if (this.isPremium) {
						this.bannerExplora = (
							await this.$http.get("/Interface/getBannersPremium")
						).data;
					} else {
						this.bannerExplora = (
							await this.$http.get("/Interface/getBanners")
						).data;
					}
				} else {
					this.bannerExplora = (
						await this.$http.get(`/Interface/getBannersTeams/${this.team}`)
					).data;
				}
			}

			this.randomBanner();
			//eslint-disable-next-line
		} catch (error) {}
	},
	data: () => {
		return {
			isIntersecting: false,
			bannerSeleccionado: {
				url: "",
				title: "",
				description: "",
				is_video: null,
			},
			bannerExplora: [],
		};
	},
	props: {
		altoBannerExplora: Number,
		altoVideo: Number,
		isPremium: {
			type: Boolean,
			default: false,
		},
		team: {
			type: String,
			default: "",
		},
		is_compraahora: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		aspectoBanner() {
			// 23 / 8
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return 39 / 13;
				case "sm":
					return 39 / 13;
				case "md":
					return 39 / 13;
				case "lg":
					return 39 / 13;
				case "xl":
					return 39 / 13;
			}
		},
	},
	methods: {
		randomBanner() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					this.bannerExplora = this.bannerExplora.filter(
						(ele) => ele.is_mobile == 1
					);
					break;
				// case 'sm':
				//   return 4 / 3
				// case 'md':
				//   return 23 / 10
				// case 'lg':
				//   return 23 / 10
				// case 'xl':
				//   return 23 / 10
			}
			let min = 0;
			let random =
				Math.floor(Math.random() * (+this.bannerExplora.length - +min)) + +min;
			this.bannerSeleccionado = this.bannerExplora[random];

			if (this.bannerSeleccionado.is_video) {
				this.$emit("isVideo", true);
				this.player_vimeo = new Player(this.$refs.videoBanner);
				this.player_vimeo.ready().then(() => {
					this.player_vimeo.play();
					this.player_vimeo.setLoop(true);
				});
				this.player_vimeo.on("end", () => {
					this.player_vimeo.ready().then(() => {
						this.player_vimeo.play();
						this.player_vimeo.setLoop(true);
					});
				});
			}
		},
		onIntersect(entries, observer) {
			this.isIntersecting = entries[0].isIntersecting;

			if (this.bannerSeleccionado.is_video) {
				this.player_vimeo = new Player(this.$refs.videoBanner);
				if (this.isIntersecting) {
					this.player_vimeo.ready().then(() => {
						this.player_vimeo.play();
						this.player_vimeo.setLoop(true);
					});
				} else {
					this.player_vimeo.pause();
				}
			}
			// eslint-disable-next-line no-console
			// console.log(observer, entries)
			observer;
		},
	},
};
</script>
