<template>
  <v-dialog v-model="addRutaTrack.createRouteDialog" max-width="450">
    <v-card
      light
      elevation="24"
      max-width="444"
      class="mx-auto"
      :class="{
        'niu-back-dialog-0': createRouteCarousel == 0,
        'niu-back-dialog-1': createRouteCarousel == 1,
        'niu-back-dialog-2': createRouteCarousel == 2
      }"
    >
      <v-carousel
        light
        v-model="createRouteCarousel"
        :continuous="false"
        :show-arrows="false"
        hide-delimiters
        delimiter-icon="mdi-minus"
        height="80vh"
        style="background-color:transparent;"
      >
        <v-carousel-item
          class="pa-5 mb-5"
          style="background-color:transparent; color:black !important"
        >
          <div style="position: relative; top: 17%; text-align: center;">
            <span class="display-1" style="font-size: 3rem !important;">
              ¡Genial!
            </span>
            <p style="margin-top: 20px;font-size: 1.5rem !important;">
              Has seleccionado el track.
            </p>
            <p style="margin-top: 20px;font-size: 1.5rem !important;">
              Haz click en "SIGUIENTE" para ponerle nombre a tu contenido y
              crear tu ruta de aprendizaje.
            </p>
          </div>
        </v-carousel-item>
        <v-carousel-item style="background-color:transparent;">
          <CreateRouteResume
            grado="track"
            :ruta="addRutaTrack.modulos"
          ></CreateRouteResume>
          <v-text-field
            label
            placeholder="Nombre de la Ruta"
            filled
            color="#000"
            v-model="addRutaTrack.trackTitle"
            readonly
            required
          ></v-text-field>
          <div v-if="addRutaTrack.creatingRoute">
            <v-progress-linear
              indeterminate
              color="yellow darken-2"
            ></v-progress-linear>
          </div>
        </v-carousel-item>
        <v-carousel-item style="background-color:transparent;">
          <div class="d-flex flex-column justify-center align-center pa-7">
            <span
              class="display-1"
              style="text-align: center; margin-top: 30px;"
            >
              ¡Felicitaciones!
            </span>
            <span
              class="display-1"
              style="text-align: center; margin-top: 30px;font-size: 1.5rem !important;"
            >
              ¡Has creado exitosamente tu ruta de aprendizaje!
            </span>

            <span
              class="display-1"
              style="text-align: center; margin-top: 30px;font-size: 1.5rem !important;"
            >
              Bienvenid@ a la <br />"re-evolución" educativa.
            </span>
            <v-btn
              to="/progreso"
              style=" font-size: 2rem;margin-top: 30px; color: #590d53; font-weight: 1000; background-color: #ffffff;"
            >
              Ir a "Mis rutas de aprendizaje"
            </v-btn>
          </div>
        </v-carousel-item>
      </v-carousel>
      <div class="d-flex justify-end pa-3">
        <v-btn
          v-if="createRouteCarousel !== 2"
          color="success"
          @click="
            createRouteCarousel === 0
              ? createRouteCarousel++
              : createRouteCarousel--
          "
          >{{ createRouteCarousel === 0 ? 'Siguiente' : 'Volver' }}</v-btn
        >
        <v-btn
          v-if="createRouteCarousel === 1"
          color="primary"
          class="ml-2"
          @click="confirmCreateRoute(modulos)"
          >Crear ruta</v-btn
        >
        <v-btn color="primary" @click="closeDialog">Cerrar</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import CreateRouteResume from './CreateRouteResume.vue'
// import { EventBus } from '../../plugins/event-bus.js'
export default {
  components: {
    CreateRouteResume
  },
  computed: {
    ...mapState(['addRutaTrack'])
  },
  methods: {
    ...mapMutations(['SET_ALERT','SET_ADDRUTATRACK']),

    closeDialog () {
      this.SET_ADDRUTATRACK({
        modulos: [],
        createRouteDialog: false,
        trackTitle: ''
      })
    },
    confirmCreateRoute () {
      if (this.addRutaTrack.modulos.length > 0) {
        this.creatingRoute = true
        this.$http
          .post('universos/createTrackNiufleex', {
            id: parseInt(this.addRutaTrack.modulos[0].trackId)
          })
          //eslint-disable-next-line
          .then(response => {
            this.creatingRoute = false
            this.createRouteCarousel = 2
          })
          .catch(error => {
            error
          })
      } else {
        this.SET_ALERT({
          message: 'Falta el nombre de la ruta que estas creando',
          type: 'error',
          snackbar: true
        })
      }
    }
  },
  data: () => {
    return {
      creatingRoute: false,
      createRouteCarousel: 0, //0 original,
      modulos: [],
      createRouteDialog: false,
      trackTitle: ''
    }
  }
}
</script>
